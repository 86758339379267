import React, { useEffect, useState } from "react";
import * as rdd from "react-device-detect";
import { TrackedLink } from "..";

import AndroidIcon from "../../images/icons/android.svg";
import AppleIcon from "../../images/icons/apple.svg";
import WindowsIcon from "../../images/icons/windows.svg";

import "./get-started-ctas.scss";

/**
 * Hard coded download values.
 */
const DOWNLOADS = {
  Android: {
    link: "https://play.google.com/store/apps/details?id=com.partie",
    icon: AndroidIcon,
  },
  iOS: {
    link: "itms-appss://apps.apple.com/us/app/partie/id1520305781?uo=4",
    icon: AppleIcon,
  },
  macOS: {
    link: "https://cdn.partie.com/downloads/Partie-Installer_1.0.1.dmg",
    icon: AppleIcon,
  },
  Windows: {
    link: "https://cdn.partie.com/downloads/PartieSetup_1.0.1.exe",
    icon: WindowsIcon,
  },
};

export const getOS = () => {
  let userSystem;

  if (rdd.isAndroid) {
    userSystem = "Android";
  } else if (rdd.isIOS) {
    userSystem = "iOS";
  } else if (rdd.isWindows) {
    userSystem = "Windows";
  } else if (rdd.isMacOs) {
    userSystem = "macOS";
  }

  return userSystem;
};

const GetStartedCtas = () => {
  const [os, setOs] = useState(undefined);
  const download = DOWNLOADS[os];

  /**
   *
   * On mount check for the user's OS.
   *
   */
  useEffect(() => {
    if (typeof window.navigator !== undefined) {
      const userOs = getOS();
      setOs(userOs);
    }
  }, []);

  return (
    <>
      {download && (
        <TrackedLink
          type="btn get-started-cta"
          to={download?.link}
          rel="noreferrer"
          target="_blank"
          children={<><img src={download?.icon} alt={os} className="btn__icon" loading="lazy" />
            Download for {os}</>}
        />
      )}
      <TrackedLink
        type="btn btn--secondary get-started-cta"
        to="https://app.partie.com"
        rel="noreferrer"
        children={"Open Partie in Browser"}
      />
    </>
  );
};

export default GetStartedCtas;
