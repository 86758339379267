import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { PageLayout } from "../layouts";
import { Block, GetTheAppBlock } from '../components';

import { getOS } from "../components/get-started-ctas/get-started-ctas";

import "../global/styles/page-styles/download-page.scss";

import HeroImg from "../images/download-hero.svg";

const DOWNLOADS = {
  Android: {
    link: "https://play.google.com/store/apps/details?id=com.partie",
  },
  iOS: {
    link: "itms-appss://apps.apple.com/us/app/partie/id1520305781?uo=4",
  },
  macOS: {
    link: "https://cdn.partie.com/downloads/Partie-Installer_1.0.1.dmg",
    requirements: "MacOS 10.10 (Yosemite) or higher.",
  },
  Windows: {
    link: "https://cdn.partie.com/downloads/PartieSetup_1.0.1.exe",
    requirements: "Windows 10 required",
  },
};

const DownloadPage = () => {
  const [os, setOs] = useState(undefined);
  const download = DOWNLOADS[os];

  /**
   *
   * On mount check for the user's OS.
   *
   */
  useEffect(() => {
    if (typeof window.navigator !== undefined) {
      const userOs = getOS();
      setOs(userOs);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Partie | Download</title>
        <html lang="en" />
        <meta name="description" content="Download the Partie App Today!" />
      </Helmet>
      <PageLayout className="download-page">
        <Block hero lazy>
          <div className="partie-block__column">
            {download && (
              <>
                <h2 className="partie-block__title">{`Get Partie for ${os}`}</h2>
                {download.requirements && (
                  <p className="partie-block__description">
                    {download.requirements}
                  </p>
                )}
                <div className="partie-block__cta">
                  <a
                    href={download.link}
                    className="btn"
                    title="Download Partie"
                  >
                    Download
                  </a>
                </div>
              </>
            )}
          </div>
          <div className="partie-block__column">
            <img src={HeroImg} alt="Download Partie" />
          </div>
        </Block>
        <GetTheAppBlock />
      </PageLayout>
    </>
  );
};

export default DownloadPage;